import { createSlice } from '@reduxjs/toolkit';
import {
  getDistricts,
  getCompanies,
  getSelectedCompanyData,
  getSelectedCompanyOtherData,
  getCompaniesUnregistered,
} from './thunks';
import { trackEvent } from '../../../utils/general/amplitudeService';

const initialState = {
  searchResults: [],
  districts: [],
  selectedCompany: null,
  selectedCompanyData: null,
  selectedCompanyOtherData: null,
  selectedCompanyOtherDataPending: false,
  visibleCompanyView: 'table',
  getDistrictsPending: false,
  getCompaniesPending: false,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setSelectedCompany: (state, { payload }) => {
      state.selectedCompany = payload;
      state.visibleCompanyView = payload ? 'details' : 'table';
    },
    setVisibleCompanyView: (state, { payload }) => {
      state.visibleCompanyView = payload;
    },
    setSearchResults: (state, { payload }) => {
      state.searchResults = payload;
    },
    resetCompanySearch: () => initialState,
  },
  extraReducers: {
    [getDistricts.fulfilled]: (state, { payload }) => {
      state.districts = payload;
      state.getDistrictsPending = false;
    },
    [getDistricts.pending]: state => {
      state.getDistrictsPending = true;
    },
    [getDistricts.rejected]: state => {
      state.getDistrictsPending = false;
    },
    [getCompanies.fulfilled]: (state, { payload, meta, type }) => {
      state.searchResults = payload;
      trackEvent('Search Results', { totalSize: payload.length, ...meta?.arg, type });
      state.getCompaniesPending = false;
    },
    [getCompanies.pending]: state => {
      state.getCompaniesPending = true;
    },
    [getCompanies.rejected]: state => {
      state.getCompaniesPending = false;
    },
    [getCompaniesUnregistered.fulfilled]: (state, { payload, meta, type }) => {
      state.searchResults = payload;
      trackEvent('Search Results', { totalSize: payload.length, ...meta?.arg, type });
      state.getCompaniesPending = false;
    },
    [getCompaniesUnregistered.pending]: state => {
      state.getCompaniesPending = true;
    },
    [getCompaniesUnregistered.rejected]: state => {
      state.getCompaniesPending = false;
    },
    [getSelectedCompanyData.fulfilled]: (state, { payload }) => {
      state.selectedCompanyData = payload;
      state.selectedCompanyDataPending = false;
    },
    [getSelectedCompanyData.pending]: state => {
      state.selectedCompanyDataPending = true;
    },
    [getSelectedCompanyData.rejected]: state => {
      state.selectedCompanyDataPending = false;
    },
    [getSelectedCompanyOtherData.fulfilled]: (state, { payload }) => {
      state.selectedCompanyOtherData = payload;
      state.selectedCompanyOtherDataPending = false;
    },
    [getSelectedCompanyOtherData.pending]: state => {
      state.selectedCompanyOtherData = null;
      state.selectedCompanyOtherDataPending = true;
    },
    [getSelectedCompanyOtherData.rejected]: state => {
      state.selectedCompanyOtherData = null;
      state.selectedCompanyOtherDataPending = false;
    },
  },
});

export const { setSelectedCompany, setVisibleCompanyView, resetCompanySearch, setSearchResults } = slice.actions;

export default slice.reducer;
