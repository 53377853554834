import { Card, Col, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

const BuyReportLink = ({ handleBuyReport, removeOnSmallScreen = false, removeOnLargeScreen = false }) => {
  const { t } = useTranslation();

  return (
    <Col
      xs={{ order: 1, span: removeOnSmallScreen ? 0 : 24 }}
      sm={{ order: 1, span: removeOnSmallScreen ? 0 : 24 }}
      md={{ order: 1, span: removeOnSmallScreen ? 0 : 24 }}
      lg={{ order: 2, span: removeOnLargeScreen ? 0 : 12 }}
      xl={{ order: 2, span: removeOnLargeScreen ? 0 : 12 }}
      xxl={{ order: 2, span: removeOnLargeScreen ? 0 : 12 }}
    >
      <Card
        style={{ height: 'calc(100% - 32px)', background: 'rgba(10, 122, 0, 0.1)' }}
        className="card-simple card-simple-padding-none space-vertical-sm has-shadow buy-report-card"
      >
        <span>
          <Trans i18nKey="companyResult.text.buyReport" components={{ bold: <strong /> }} />
        </span>
        <Button className="btn-success" onClick={handleBuyReport}>
          {t('companyResult.button.buyReport')}
        </Button>
      </Card>
    </Col>
  );
};

export default BuyReportLink;
