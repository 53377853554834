import { Col, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as Rating } from 'assets/img/icons/rating.svg';

import CollapsibleCard from 'components/reusable/CollapsibleCard';
import { prescoreData } from 'store/entities/companySearchResult/selectors/unregisteredReportSelector';
import CompanyDataCard from 'components/reusable/CompanyDataCard';
import PrescoreChart from './PrescoreChart';
import CreditRiskGraph from '../../../../components/reusable/graphs/CreditRiskGraph';
import { getEconomicStatus } from '../../../PrescoreReport/utils/prescoreHelpers';
import { BlockHeader } from 'components/reusable/BlockHeader';

const { Paragraph } = Typography;

const Prescore = () => {
  const { t } = useTranslation();
  const { prescore } = useSelector(prescoreData);

  if (!prescore) return null;

  const { indicator, averageSalariesIndicator, economicStatusIndicator, sodraDebtsIndicator } = prescore;

  const economyStatus = getEconomicStatus(
    indicator === 3 ? -1 : economicStatusIndicator,
    'prescoreReport.prescore.economic.status.progress'
  );
  const averageSalaries = getEconomicStatus(
    indicator === 3 ? -1 : averageSalariesIndicator,
    'prescoreReport.prescore.average.salary.progress'
  );
  const sodraDebts = getEconomicStatus(
    indicator === 3 ? -1 : sodraDebtsIndicator,
    'prescoreReport.prescore.sodra.debts.progress'
  );

  // Credit history bar by default is inactive if user is unauthenticated
  const creditHistory = getEconomicStatus(-1, 'prescoreReport.prescore.credit.history.progress');

  return (
    <>
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 24, order: 2 }}
        lg={{ span: 24, order: 1 }}
        xl={{ span: 24, order: 1 }}
        xxl={{ span: 24, order: 1 }}
      >
        <BlockHeader icon={<Rating className="icon--gray" />} title={t('companyResult.prescore.title')} />
        <Row className="credit-risk-graph-container content-blur">
          <Col span={24}>
            <CreditRiskGraph value={indicator} />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="prescore-chart content-blur">
            <PrescoreChart data={[economyStatus, sodraDebts, averageSalaries, creditHistory]} />
          </Col>
        </Row>
        <CollapsibleCard title={t('companyResult.prescore.desc.title')} hideByDefault>
          {['creditRisk', 'economic', 'creditHistory', 'soDra'].map(value => (
            <Paragraph>
              <Trans i18nKey={`companyResult.prescore.desc.${value}`} components={{ bold: <strong /> }} />
            </Paragraph>
          ))}
        </CollapsibleCard>
      </Col>
    </>
  );
};

export default Prescore;
