export const getEventData = (target, location, language, isAuthenticated) => {
  let eventName = '';
  let eventProperties = {};

  // Check if the target or any of its ancestors is a clickable element
  if (isClickableElement(target)) {
    eventName = 'Button Clicked';
    eventProperties = createEventProperties(target, location, language, isAuthenticated, 'Unnamed Button');
  } else if (target.matches('input[type="checkbox"]')) {
    eventName = 'Checkbox Toggled';
    eventProperties = {
      ...createEventProperties(target, location, language, isAuthenticated, 'Unnamed Checkbox'),
      checked: target.checked,
    };
  } else if (target.matches('input') || target.closest('input')) {
    eventName = 'Input Clicked';
    eventProperties = createEventProperties(target, location, language, isAuthenticated, 'Unnamed Input');
  }

  return eventName ? { name: eventName, properties: eventProperties } : null;
};

const createEventProperties = (target, location, language, isAuthenticated, defaultLabel) => ({
  clickedElement: target.outerHTML,
  text: target.innerText || target.getAttribute('aria-label') || target.getAttribute('href') || defaultLabel,
  href: target.getAttribute('href') || 'No href',
  pagePath: location.pathname,
  pageUrl: window.location.href,
  'logged in': isAuthenticated,
  language,
});

const isClickableElement = target => {
  // Define the set of conditions for a clickable element
  return (
    target.matches('button') ||
    target.closest('button') ||
    target.matches('.ant-menu-item') ||
    target.closest('.ant-menu-item') ||
    target.matches('a') ||
    target.closest('a') ||
    target.matches('.ant-table-row-clickable') ||
    target.closest('.ant-table-row-clickable') ||
    target.matches('.anticon') ||
    target.closest('.anticon') ||
    target.matches('#select-row') ||
    target.closest('#select-row') ||
    (target.matches('p') && target.closest('.ant-table-row-clickable')) // Added to check if <p> in a table row
  );
};
