import { createSlice } from '@reduxjs/toolkit';
import { getCountries, getCompanies, getCompanyOrderId, getCompanyPdfReport, getHistoricalCompanies } from './thunks';
import { trackEvent } from '../../../utils/general/amplitudeService';

const initialState = {
  searchResults: [],
  countries: [],
  selectedCompany: null,
  chargeModal: null,
  orderReportModal: null,
  historicalCompanies: null,
  historicalCompaniesPending: false,
  selectedCompanyOrderId: null,
  visibleCompanyView: 'table',
  getCountriesPending: false,
  getCompaniesPending: false,
  selectedCompanyPdfReport: null,
  selectedCompanyPdfReportPending: false,
};

const slice = createSlice({
  name: 'foreignCompany',
  initialState,
  reducers: {
    setChargeModal: (state, { payload }) => {
      state.chargeModal = payload;
    },
    setOrderReportModal: (state, { payload }) => {
      state.orderReportModal = payload;
    },
    setSelectedCompany: (state, { payload }) => {
      state.selectedCompany = payload;
      state.visibleCompanyView = payload ? 'details' : 'table';
    },
    setSelectedCompanyOrderId: (state, { payload }) => {
      state.selectedCompanyOrderId = payload;
      state.visibleCompanyView = payload ? 'details' : 'table';
    },
    setForeignCompanySearch: (state, { payload }) => {
      state.foreignCompanySearch = payload;
    },
    setVisibleCompanyView: (state, { payload }) => {
      state.visibleCompanyView = payload;
    },
    setSearchResults: (state, { payload }) => {
      state.searchResults = payload;
    },
    resetCompanySearch: () => initialState,
  },
  extraReducers: {
    [getCountries.fulfilled]: (state, { payload }) => {
      state.countries = payload;
      state.getCountriesPending = false;
    },
    [getCountries.pending]: state => {
      state.getCountriesPending = true;
    },
    [getCountries.rejected]: state => {
      state.getCountriesPending = false;
    },
    [getCompanies.fulfilled]: (state, { meta, payload, type }) => {
      state.selectedCompany = null;
      state.searchResults = payload;
      trackEvent('Search Results', { totalSize: payload.totalSize, ...meta?.arg, type });
      state.getCompaniesPending = false;
    },
    [getCompanies.pending]: state => {
      state.selectedCompany = null;
      state.getCompaniesPending = true;
    },
    [getCompanies.rejected]: state => {
      state.getCompaniesPending = false;
    },
    [getHistoricalCompanies.fulfilled]: (state, { payload }) => {
      state.historicalCompanies = payload;
      state.historicalCompaniesPending = false;
    },
    [getHistoricalCompanies.pending]: state => {
      state.historicalCompaniesPending = true;
    },
    [getHistoricalCompanies.rejected]: state => {
      state.historicalCompaniesPending = false;
    },
    [getCompanyOrderId.fulfilled]: (state, { payload }) => {
      state.selectedCompanyOrderId = payload.id;
      state.selectedCompanyOrderIdPending = false;
      state.selectedCompanyOrderIdError = false;
      state.selectedCompanyPdfReport = null;
    },
    [getCompanyOrderId.pending]: state => {
      state.selectedCompanyPdfReport = null;
      state.selectedCompanyOrderIdPending = true;
      state.selectedCompanyOrderIdError = false;
      state.selectedCompanyOrderId = null;
    },
    [getCompanyOrderId.rejected]: state => {
      state.selectedCompanyPdfReport = null;
      state.selectedCompanyOrderId = null;
      state.selectedCompanyOrderIdError = true;
      state.selectedCompanyOrderIdPending = false;
    },
    [getCompanyPdfReport.fulfilled]: (state, { payload }) => {
      state.selectedCompanyPdfReport = payload;
      state.selectedCompanyPdfReportPending = false;
    },
    [getCompanyPdfReport.pending]: state => {
      state.selectedCompanyPdfReport = null;
      state.selectedCompanyOrderId = null;
      state.selectedCompanyPdfReportPending = true;
    },
    [getCompanyPdfReport.rejected]: state => {
      state.selectedCompanyPdfReportPending = false;
    },
  },
});

export const {
  setChargeModal,
  setOrderReportModal,
  setSelectedCompany,
  setForeignCompanySearch,
  setSelectedCompanyOrderId,
  setVisibleCompanyView,
  resetCompanySearch,
  setSearchResults,
} = slice.actions;

export default slice.reducer;
