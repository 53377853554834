import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Steps from 'components/reusable/Steps';
import ReportSelection from 'views/CompanySearchResult/components/BuyReport/ReportSelection';
import OrderInformation from 'views/CompanySearchResult/components/BuyReport/OrderInformation';
import OrderConfirmation from 'views/CompanySearchResult/components/BuyReport/OrderConfirmation';
import { buyReportData } from 'store/entities/companySearchResult/selectors/buyReportSelector';
import { setBuyReportStep } from 'store/entities/companySearchResult';

import { Col, Row } from 'antd';
import { trackEvent } from '../../../../utils/general/amplitudeService';
import useCurrentLanguage from '../../../../hooks/user/useCurrentLanguage';

const BuyReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const { step, completedSteps } = useSelector(buyReportData);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      trackEvent('Buy report steps', {
        activeStep: step,
        language: lang,
      });
    }, 10);

    return () => clearTimeout(timer);
  }, [step]);

  const steps = [
    {
      text: t('companyResult.buyReport.step.report'),
      orderNr: 1,
    },
    {
      text: t('companyResult.buyReport.step.orderInfo'),
      orderNr: 2,
    },
    {
      text: t('companyResult.buyReport.step.orderConfirmation'),
      orderNr: 3,
    },
  ];

  const handleSetPage = page => {
    if (page < step) {
      dispatch(setBuyReportStep(page));
    }
    if (completedSteps.includes(step) && page === step + 1) {
      dispatch(setBuyReportStep(page));
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} className="space-bottom-lg">
        <Col xs={24}>
          <Steps steps={steps} activeStep={step} setStep={handleSetPage} />
        </Col>
      </Row>
      {step === 1 && <ReportSelection />}
      {step === 2 && <OrderInformation />}
      {step === 3 && <OrderConfirmation />}
    </>
  );
};

export default BuyReport;
