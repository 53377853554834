import axios from 'axios';
import jwt_decode from 'jwt-decode';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { showErrorNotification } from 'utils/notification';
import { signOut } from 'store/entities/user/thunks';
import { setSessionKick } from 'store/entities/user';
import { trackEvent } from '../utils/general/amplitudeService';

export const API_PATH = process.env.REACT_APP_API_PATH ?? '/api/v1';

export const fetchFromApi = (path, options = defaultRequestOptions()) =>
  axios({
    url: `${API_PATH}${path}`,
    ...options,
  })
    .then(response => response.data)
    .catch(error => {
      return handleApiError({ path, error, options });
    });

export const handleApiError = ({ path, error, options }) => {
  (error.response.data.messages || error.response.data.message) &&
    showErrorNotification(error.response.data.messages ? error.response.data.messages[0] : error.response.data.message);
  const sanitizedData = options?.data ? { ...options.data, password: undefined } : undefined;

  trackEvent('Error', {
    errorType: `System ${error.response.status}`,
    errorMessage: error.response.data.messages ? error.response.data.messages[0] : error.response.data.message,
    pagePath: `${API_PATH}${path ? path : undefined}`,
    ...sanitizedData,
  });
  if (error.response.status === 401) handleUnauthorized(error.response);
  return Promise.reject(error);
};

const handleUnauthorized = resp => {
  const exceptions = ['change-password'];
  if (!exceptions.some(e => resp.config.url.includes(e))) {
    import('../store').then(store => {
      const dispatch = store.default.dispatch;
      if (dispatch && resp.config.headers.Authorization) {
        dispatch(signOut());
      }
      dispatch(setSessionKick(true));
    });
  }
};

const defaultRequestOptions = () => {
  const token = window.localStorage.getItem('token');
  return {
    headers: {
      ...(!!token && {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      }),
      'Content-Type': 'application/json',
    },
  };
};

const fileRequestOptions = () => {
  const token = window.localStorage.getItem('token');
  return {
    headers: {
      ...(!!token && {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      }),
      'Content-Type': 'multipart/form-data',
    },
  };
};

export const optionsForFilePostRequest = data => ({
  ...fileRequestOptions(),
  method: 'post',
  data,
});

export const optionsForPostRequest = data => ({
  ...defaultRequestOptions(),
  method: 'post',
  data,
});

export const optionsForGetRequest = data => ({
  ...defaultRequestOptions(),
  method: 'get',
});

export const optionsForDeleteRequest = data => ({
  ...defaultRequestOptions(),
  method: 'delete',
  data,
});

export const optionsForPutRequest = data => ({
  ...defaultRequestOptions(),
  method: 'put',
  data,
});

export const optionsForPatchRequest = data => ({
  ...defaultRequestOptions(),
  method: 'patch',
  ...(data && { data }),
});

export const optionsForFileRequest = data => ({
  headers: new Headers({
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
  }),
  method: 'post',
  data,
});

export const notifyApiError = (i18n, err) => showErrorNotification(i18n.t(err.key ?? err.code));

axios.interceptors.request.use(
  async config => {
    const expireAt = localStorage.getItem('tokenExp');
    const token = localStorage.getItem('token');

    const timeUntilEndOfSession = differenceInSeconds(new Date(expireAt * 1000), new Date());
    if (token && timeUntilEndOfSession < 300) {
      //check this
      return await fetch(`${API_PATH}/account/refresh-token`, {
        method: 'POST',
        headers: {
          ...(!!token && {
            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
          }),
          'Content-Type': 'application/json',
        },
      })
        .then(async res => {
          if (res.status === 200) {
            const parsed = await res.json();
            localStorage.setItem('token', parsed.accessToken);
            localStorage.setItem('tokenExp', jwt_decode(parsed.accessToken)?.exp);
            config.headers = {
              Authorization: `Bearer ${parsed.accessToken}`,
            };
            return config;
          }
          if (res.status === 401) {
            handleApiError(res);
            Promise.reject(res);
          }
        })
        .catch(error => handleApiError(error));
    }
    return config;
  },
  err => {
    Promise.reject(err);
  }
);
