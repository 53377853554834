import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_KEY;

let isAmplitudeInitialized = false;

export const initializeAmplitude = () => {
  if (isAmplitudeInitialized || !AMPLITUDE_API_KEY) return;

  amplitude.add(sessionReplayPlugin({ sampleRate: 1 }));
  amplitude.init(AMPLITUDE_API_KEY, {
    serverZone: 'EU',
    autocapture: { pageViews: false },
  });

  isAmplitudeInitialized = true;
};

export const logEvent = (eventName, eventProperties = {}) => {
  if (!isAmplitudeInitialized) return;
  amplitude.track(eventName, eventProperties);
};
export const setUserProperties = properties => {
  const identify = new amplitude.Identify();

  Object.keys(properties).forEach(key => {
    identify.set(key, properties[key]);
  });

  amplitude.identify(identify);
};

export const logPageView = (path, url, queryParams, language, authenticated) => {
  logEvent('Page Viewed', {
    'page path': path,
    'page url': url,
    'query params': queryParams,
    language,
    'logged in': authenticated,
  });
};

export const trackEvent = (eventName, properties) => {
  if (!isAmplitudeInitialized) return;
  logEvent(eventName, { 'page Url': window.location.href, ...properties });
};
