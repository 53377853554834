import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ProfReportLT from 'assets/pdf/prof-report_lt-LT.pdf';
import ProfReportEN from 'assets/pdf/prof-report_en-US.pdf';
import FinancialReportLT from 'assets/pdf/financial-report_lt-LT.pdf';
import FinancialReportEN from 'assets/pdf/financial-report_en-US.pdf';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { buyReportData } from 'store/entities/companySearchResult/selectors/buyReportSelector';
import {
  completeStep,
  unCompleteStep,
  setBuyReportSelectedReport,
  setBuyReportDetails,
  setBuyReportStep,
  setPage,
} from 'store/entities/companySearchResult';
import OrderActions from 'views/CompanySearchResult/components/BuyReport/OrderActions';
import { basicData } from 'store/entities/companySearchResult/selectors/unregisteredReportSelector';
import SingleResult from 'components/reusable/SingleResult';

import { Card, Col, Form, Button, Row, Radio } from 'antd';
import i18n from 'i18next';
import useFormatDate from '../../../../hooks/format/useFormatDate';
import { getCompanyFinStatDataUnregisteredThunk } from '../../../../store/entities/company/thunks';
import { useParams } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import LoadingSpinner from 'components/reusable/LoadingSpinner';
import useWindowSize from 'hooks/dom/useWindowSize';

const getReportExampleByLang = (lang, type) => {
  if (type === 'professionalReport') {
    switch (lang) {
      case 'lt-LT':
        return ProfReportLT;
      case 'en-US':
        return ProfReportEN;
      default:
        return ProfReportLT;
    }
  } else if (type === 'financialStatement') {
    switch (lang) {
      case 'lt-LT':
        return FinancialReportLT;
      case 'en-US':
        return FinancialReportEN;
      default:
        return FinancialReportLT;
    }
  }
};

const ReportSelection = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const { selectedReport, selectedReportType } = useSelector(buyReportData);
  const isFinancialStatement = selectedReportType === 'financialStatement';
  const isProfessionalReport = selectedReportType === 'professionalReport';
  const { basicData: basicFinancialData, basicDataPending } = useSelector(state => state.companyFinancialReport);
  const { companyName, companyCode, status, address } = useSelector(basicData);

  const formatDate = useFormatDate();

  useEffect(() => {
    if (isProfessionalReport) {
      dispatch(completeStep(1));
    }

    if (isFinancialStatement && !selectedReport) {
      dispatch(unCompleteStep(1));
    }

    if (isFinancialStatement && selectedReport) {
      dispatch(completeStep(1));
    }
  }, [selectedReport, selectedReportType]);

  useEffect(() => {
    companyId && dispatch(getCompanyFinStatDataUnregisteredThunk(companyId));
  }, []);

  const handleCompleteStep = () => dispatch(setBuyReportStep(2));

  const handleBackButton = () => dispatch(setPage('report'));

  const getNextBtnDisabledStatus = () => {
    if (basicDataPending || (selectedReportType === 'financialStatement' && !selectedReport)) return true;
  };

  const reportsData = [
    {
      productType: 6016,
      type: 'professionalReport',
      msg1: true,
      msg2: true,
      msg3: true,
      msg4: true,
      msg5: true,
      msg6: true,
      msg7: true,
      msg9: true,
      msg8: true,
      msg10: true,
      msg11: true,
      msg12: true,
      msg13: true,
      msg14: true,
      msg15: true,
      msg16: true,
      msg17: true,
      msg18: true,
      msg19: true,
      msg20: true,
      msg21: true,
      price: 39.0,
    },
    {
      productType: 1500,
      type: 'financialStatement',
      msg1: true,
      msg2: true,
      msg3: false,
      msg4: false,
      msg5: false,
      msg6: false,
      msg7: false,
      msg9: false,
      msg8: false,
      msg10: false,
      msg11: false,
      msg12: false,
      msg13: false,
      msg14: false,
      msg15: false,
      msg16: false,
      msg17: false,
      msg18: false,
      msg19: false,
      msg20: false,
      msg21: false,
      price: 9.9,
    },
  ];

  const ReportValueRow = ({ text, value }) => {
    return (
      <Row className={`${value ? 'include-value' : 'exclude-value'} space-vertical-xs`}>
        <Col span={2} className="align-items-center">
          {value ? <CheckOutlined /> : <CloseOutlined />}
        </Col>
        <Col span={22}>{text}</Col>
      </Row>
    );
  };

  const ReportsSelection = ({ data, onSelect = null }) => {
    return (
      <div className="report-selecting-card" onClick={onSelect}>
        <Card
          className={`space-vertical-lg ${data.type} ${
            data.type === selectedReportType ? 'active-report' : 'non-active-report'
          }`}
          id={data.type}
          title={
            <>
              <Row>
                <Col span={24} className="type">
                  {t(`companyResult.buyReport.reportTable.${data.type}`)}
                </Col>
                <Col span={24} className="price">
                  <Trans i18nKey="companyResult.buyReport.reportTable.price" values={{ price: data.price }} />
                </Col>
              </Row>
            </>
          }
        >
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg1')} value={data.msg1} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg2')} value={data.msg2} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg3')} value={data.msg3} />
          <ReportValueRow
            text={
              <Trans
                i18nKey="companyResult.buyReport.reportTable.msg4"
                components={{ small: <span className="small-text" /> }}
              />
            }
            value={data.msg4}
          />
          <ReportValueRow
            text={
              <Trans
                i18nKey="companyResult.buyReport.reportTable.msg5"
                components={{ small: <span className="small-text" /> }}
              />
            }
            value={data.msg5}
          />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg6')} value={data.msg6} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg7')} value={data.msg7} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg8')} value={data.msg8} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg9')} value={data.msg9} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg10')} value={data.msg10} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg11')} value={data.msg11} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg12')} value={data.msg12} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg13')} value={data.msg13} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg14')} value={data.msg14} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg15')} value={data.msg15} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg16')} value={data.msg16} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg17')} value={data.msg17} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg18')} value={data.msg18} />
          <ReportValueRow
            text={
              <Trans
                i18nKey="companyResult.buyReport.reportTable.msg19"
                components={{ small: <span className="small-text" /> }}
              />
            }
            value={data.msg19}
          />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg20')} value={data.msg20} />
          <ReportValueRow text={t('companyResult.buyReport.reportTable.msg21')} value={data.msg21} />
          <Row>
            <Col span={2} className="align-items-center"></Col>
            <Col span={22}>
              <span className="small-text">{t('companyResult.buyReport.reportTable.ps')}</span>
            </Col>
          </Row>
          <Row>
            <Col span={2} className="align-items-center"></Col>
            <Col span={22}>
              <span className="small-text">{t('companyResult.buyReport.reportTable.pss')}</span>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-center space-top-lg">
              <a href="#" onClick={() => window.open(`${getReportExampleByLang(i18n.language, data.type)}`, '_blank')}>
                <Button className={data.type === 'professionalReport' ? 'btn-success' : 'btn-gray'}>
                  {t('companyResult.buyReport.reportTable.example')}
                </Button>
              </a>
            </Col>
          </Row>
        </Card>
      </div>
    );
  };

  const FinancialStatementSelector = () => {
    return (
      isFinancialStatement && (
        <>
          {basicDataPending ? (
            <LoadingSpinner size="large" spinning={true}>
              <div></div>
            </LoadingSpinner>
          ) : (
            <Form.Item
              label={<div className="color-secondary">{t(`companyResult.buyReport.select.${selectedReportType}`)}</div>}
              className="buy-report-select"
            >
              <Radio.Group
                onChange={value => {
                  dispatch(setBuyReportSelectedReport(value.target.value));
                }}
                value={selectedReport}
              >
                {basicFinancialData?.basicInformation?.map(data => {
                  return (
                    <Radio key={data.financialStatementId} value={data.financialStatementId}>
                      <Trans
                        i18nKey="companyResult.buyReport.chooseReport.option"
                        values={{
                          year: data.financialYear,
                          month: data.financialMonth,
                          category: data.category,
                          begin: formatDate(data.financialYearBegin),
                          end: formatDate(data.financialYearEnd),
                          source: data.source,
                        }}
                      />
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          )}
        </>
      )
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <SingleResult carded result={{ code: companyCode, name: companyName, status, address }} />
        </Col>
        <Col span={24} style={{ marginBottom: 40 }}>
          <OrderActions
            handleBackButton={handleBackButton}
            handleContinueButton={handleCompleteStep}
            isNextBtnDisabled={getNextBtnDisabledStatus()}
            backBtnId="company-result-back-first"
            submitBtnId="company-result-next-first"
          />
        </Col>
        <Col span={24}>
          {windowWidth <= 991 ? (
            <Swiper
              effect={'cards'}
              modules={[EffectCards, Navigation]}
              grabCursor={true}
              spaceBetween={20}
              navigation={true}
              onSlideChange={value => {
                dispatch(
                  setBuyReportDetails({
                    reportType: reportsData[value.activeIndex].type,
                    productType: reportsData[value.activeIndex].productType,
                    productPrice: reportsData[value.activeIndex].price,
                  })
                );
                dispatch(setBuyReportSelectedReport(null));
              }}
              initialSlide={reportsData.findIndex(report => report.type === selectedReportType) || 0}
            >
              {reportsData?.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <ReportsSelection data={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <Row>
              {reportsData?.map((item, i) => {
                return (
                  <Col span={12}>
                    <ReportsSelection
                      data={item}
                      onSelect={() => {
                        dispatch(
                          setBuyReportDetails({
                            reportType: reportsData[i].type,
                            productType: reportsData[i].productType,
                            productPrice: reportsData[i].price,
                          })
                        );
                        dispatch(setBuyReportSelectedReport(null));
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>
        <Col span={24}>
          <FinancialStatementSelector />
        </Col>
      </Row>
      <OrderActions
        handleBackButton={handleBackButton}
        handleContinueButton={handleCompleteStep}
        isNextBtnDisabled={getNextBtnDisabledStatus()}
        backBtnId="company-result-back-first"
        submitBtnId="company-result-next-first"
      />
    </>
  );
};

export default ReportSelection;
