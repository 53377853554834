import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useCurrentLanguage from './hooks/user/useCurrentLanguage';
import { languages } from 'i18n';
import useAuthCheck from './hooks/auth/useAuthCheck';
import useIdleSession from './hooks/user/useIdleSession';

import { ConfigProvider } from 'antd';
import AppLayout from './components/Layout';
import Router from './components/Router';
import InactiveSessionModal from 'components/Modals/InactiveSessionModal';
import { init, RouteTracker } from 'utils/general/GoogleAnalytics';
import { initializeAmplitude, logPageView, setUserProperties, trackEvent } from './utils/general/amplitudeService';
import { getEventData } from './utils/general/eventUtils';

const App = () => {
  const [isSessionInactiveModalOpen, setIsSessionInactiveModalOpen] = useState(false);
  const [initialTime, setInitialTime] = useState();
  const lang = useCurrentLanguage();
  const { hasSession, isAuthenticated } = useSelector(state => state.user);
  const location = useLocation();

  useEffect(() => {
    initializeAmplitude();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      logPageView(location.pathname, window.location.href, location.search || 'N/A', lang, isAuthenticated);
    }, 10);

    return () => clearTimeout(timer);
  }, [location, lang, isAuthenticated]);

  useEffect(() => {
    setUserProperties({ language: lang, logged_in: isAuthenticated });
  }, [lang, isAuthenticated]);

  useIdleSession({
    onIdle: () => {
      setInitialTime(new Date());
      if (!isSessionInactiveModalOpen && isAuthenticated) setIsSessionInactiveModalOpen(true);
    },
    valueToCancel: !hasSession,
  });

  useAuthCheck();

  const handleClickEvent = useCallback(
    event => {
      const target = event.target;
      const eventData = getEventData(target, location, lang, isAuthenticated);
      if (eventData) trackEvent(eventData.name, eventData.properties);
    },
    [location, lang, isAuthenticated]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickEvent);
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, [handleClickEvent]);

  return (
    <>
      <ConfigProvider locale={languages.find(({ codeShort }) => codeShort === lang)?.antdLocale}>
        <AppLayout>
          {init() && <RouteTracker />}
          <Router />
        </AppLayout>
      </ConfigProvider>
      {hasSession && isSessionInactiveModalOpen && (
        <InactiveSessionModal initialTime={initialTime} onClose={() => setIsSessionInactiveModalOpen(false)} />
      )}
    </>
  );
};

export default App;
