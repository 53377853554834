import { Trans, useTranslation } from 'react-i18next';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { Col, Row, Card, Table } from 'antd';
import { BlockHeader } from '../../BlockHeader';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ReactComponent as TableIcon } from 'assets/img/icons/sodraTable.svg';
import { ReactComponent as ChartIcon } from 'assets/img/icons/sodraChart.svg';
import useWindowSize from '../../../../hooks/dom/useWindowSize';

const CustomLegend = ({ payload }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <ul style={{ listStyle: 'none', padding: 0, display: 'flex' }}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: entry.color,
              marginRight: 5,
            }}
          />
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  </div>
);

const CustomTooltip = ({ active, payload, label, formatCurrency }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: '#ffffff', padding: '10px', border: '1px solid #ccc' }}>
        <p style={{ color: '#333333' }}>
          <strong>{label}</strong>
        </p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: '#333333', margin: 0 }}>
            <strong>{entry.name}</strong>: {formatCurrency(entry.value)}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const SocialSecurityContributions = ({ tableData = [], chartData = [] }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatCurrency = useNumberFormatter();
  const { width: windowWidth } = useWindowSize();

  const processChartData = (data, t) => {
    const groupedData = {};
    const yearsSet = new Set();

    data.forEach(item => {
      const date = new Date(item.importDate);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      yearsSet.add(year);

      if (!groupedData[month]) {
        groupedData[month] = { month: month.toString() };
      }

      groupedData[month][year] = item.socialInsuranceContributionsAmount || 0;
    });

    const yearsArray = Array.from(yearsSet).sort((a, b) => a - b);
    const monthsArray = [];

    for (let month = 1; month <= 12; month++) {
      const monthData = { month: t(`global.month.${month}`) };
      yearsArray.forEach(year => {
        monthData[year] = groupedData[month]?.[year] || 0;
      });
      monthsArray.push(monthData);
    }

    return { data: monthsArray, yearsArray };
  };

  const { data: processedData, yearsArray } = processChartData(chartData, t);

  const chartColors = ['#DFDFDF', '#7c7c7c', '#0C9CB2'];

  const createSingleRowData = tableData => {
    return tableData.reduce((acc, item) => {
      acc[item.year] = item.amount ?? 0;
      return acc;
    }, {});
  };

  // Apply the transformation
  const singleRowData = createSingleRowData(tableData);

  const columns = [
    ...tableData.map(item => ({
      title:
        item.startMonth && item.endMonth
          ? `${item.year} (${item.startMonth} - ${item.endMonth} ${t('global.month')})`
          : item.year,
      dataIndex: item.year,
      key: item.year,
      render: value => (value !== undefined ? formatCurrency(value) : '-'),
    })),
  ];

  return (
    <>
      <Card
        className="card-simple card-simple-padding-none space-vertical-sm has-shadow no-break"
        style={{ width: '100%' }}
      >
        <Row justify="start" align="middle">
          <Col>
            <BlockHeader
              icon={<TableIcon className="icon--gray" />}
              title={t('professionalReport.basicData.socialSecurityTable.title')}
            />
          </Col>
        </Row>
        {tableData.length && chartData.length ? (
          <Row>
            <Col xl={24} xxl={12}>
              <Table
                rowKey={record => record.year}
                bordered
                dataSource={[singleRowData]}
                columns={columns}
                scroll={{ x: 'max-content' }}
                pagination={false}
              />
            </Col>
          </Row>
        ) : (
          <Card className="card-transparent space-vertical-sm">
            {t('professionalReport.basicData.socialSecurityTable.noInformation')}
          </Card>
        )}
      </Card>
      {tableData.length && chartData.length ? (
        <Card
          className="card-simple card-simple-padding-none space-vertical-sm has-shadow no-break"
          style={{ width: '100%' }}
        >
          <Row justify="start" align="middle">
            <Col>
              <BlockHeader icon={<ChartIcon />} title={t('professionalReport.basicData.socialSecurityChart.title')} />
            </Col>
          </Row>
          <Row className="space-vertical-md no-break">
            <Col md={24} lg={24}>
              <ResponsiveContainer width="100%" height={isUiHidden ? 240 : 400}>
                <BarChart data={processedData} barCategoryGap={windowWidth > 1500 ? '13%' : '10%'} barGap={0}>
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip formatCurrency={formatCurrency} />} />
                  <Legend content={<CustomLegend />} />
                  {yearsArray.map((year, i) => (
                    <Bar key={year} dataKey={year} fill={chartColors[i]} width={10} />
                  ))}
                </BarChart>
              </ResponsiveContainer>
              <div>
                <Trans
                  i18nKey={'professionalReport.basicData.socialSecurityChart.source'}
                  components={{ bold: <strong /> }}
                />
              </div>
              <div>
                <Trans
                  i18nKey={'professionalReport.basicData.socialSecurityChart.note'}
                  components={{ bold: <strong /> }}
                />
              </div>
            </Col>
          </Row>
        </Card>
      ) : null}
    </>
  );
};

export default SocialSecurityContributions;
