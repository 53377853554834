import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import useRedirect from 'hooks/navigation/useRedirect';
import useUserClaims from 'hooks/user/useUserClaims';
import { getSelectedCompanyData, getSelectedCompanyOtherData } from 'store/entities/company/thunks';
import { setSelectedCompany } from 'store/entities/company';

import ReportRow from '../components/ReportRow';
import AddToMonitoring from '../components/AddToMonitoring';
import CompanyDataBlock from './CompanyDataBlock';
import LoadingSpinner from 'components/reusable/LoadingSpinner';

import { Row, Card, Col, Typography } from 'antd';
import { ReactComponent as BasicDataIcon } from 'assets/img/icons/basic.svg';
import AddToMonitoringSwitch from '../components/AddToMonitoringSwitch';
import useAllowedFeature from 'hooks/user/useAllowedFeature';
import { useParams } from 'react-router-dom';
import RestrictedAccess from '../../../components/RestrictedAccess';
import { CompanySmallBlock } from '../../../components/reusable/CompanyDataCard';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';
import { ReactComponent as ArrowUpIcon } from 'assets/img/icons/arrowGreenUp.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/img/icons/arrowRedDown.svg';
import { ReactComponent as IncomesIcon } from 'assets/img/icons/registers.svg';
import { ReactComponent as CreditIcon } from 'assets/img/icons/credit.svg';
import { ReactComponent as SodraIcon } from 'assets/img/icons/documents.svg';
import { ReactComponent as SearchesIcon } from 'assets/img/icons/searches.svg';
import { ReactComponent as MarginsIcon } from 'assets/img/icons/profits.svg';
import { ReactComponent as EmployeesIcon } from 'assets/img/icons/employee.svg';
import { addLegalEntity } from '../../../store/entities/otherProducts/thunks';
import { trackEvent } from '../../../utils/general/amplitudeService';

const { Paragraph } = Typography;

const Basic = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const lang = useCurrentLanguage();
  const userClaims = useUserClaims();
  const { companyId } = useParams();
  const formatCurrency = useNumberFormatter();

  const {
    selectedCompany,
    selectedCompanyData,
    selectedCompanyOtherData,
    selectedCompanyDataPending,
    selectedCompanyOtherDataPending,
  } = useSelector(state => state.company);
  const [monitoringModalOpen, setMonitoringModalOpen] = useState(false);
  const [addedToMonitoring, setAddedToMonitoring] = useState(false);
  const isStartReportAllowed = useAllowedFeature('webui_company_startreport');
  const currentSelectedCompany = selectedCompany || companyId;

  const isFinancialStatementsAllowed = useAllowedFeature('webui_company_financial_statements');
  const isCompanyCreditLimitAllowed = useAllowedFeature('webui_company_credit_limit');
  const isPrescoreReportAllowed = useAllowedFeature('webui_company_prescorereport');
  const isMainInformationAllowed = useAllowedFeature('webui_company_main_information');

  const setCompanyMonitoring = () => {
    dispatch(addLegalEntity(selectedCompany));
    setAddedToMonitoring(true);
  };

  useEffect(() => {
    currentSelectedCompany && dispatch(getSelectedCompanyData(currentSelectedCompany));
    currentSelectedCompany && dispatch(getSelectedCompanyOtherData(currentSelectedCompany));
  }, [selectedCompany, lang]);

  const formatNumber = (value, formatType) => {
    if (value) {
      if (formatType === 'euro') {
        return Math.abs(value) >= 100000 ? `${(value / 1000000).toFixed(1)}mln. €` : formatCurrency(value);
      } else if (formatType === 'percent') {
        return `${value} %`;
      } else {
        return value;
      }
    } else {
      return t('global.noEntries');
    }
  };

  const getCustomValue = (object, path, defaultValue = null) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && typeof acc === 'object' && key in acc ? acc[key] : defaultValue), object);
  };

  const checkIfPositiveValue = (valueArray, valueObject, formatType = null) => {
    if (valueArray && valueArray.length >= 2) {
      const value1 = getCustomValue(valueArray[0], valueObject);
      const value2 = getCustomValue(valueArray[1], valueObject);
      const difference = value1 - value2;

      return difference > 0 ? (
        <>
          <ArrowUpIcon /> {formatNumber(value1, formatType)}
        </>
      ) : (
        <>
          <ArrowDownIcon /> {formatNumber(value1, formatType)}
        </>
      );
    } else {
      return '-';
    }
  };

  useEffect(() => {
    !selectedCompanyDataPending &&
      !selectedCompanyOtherDataPending &&
      selectedCompanyData &&
      trackEvent('Entity preview', selectedCompanyData);
  }, [selectedCompanyData]);

  return (
    <LoadingSpinner size="large" spinning={selectedCompanyDataPending || selectedCompanyOtherDataPending}>
      <div className={companyId ? 'container' : ''}>
        {selectedCompanyData && (
          <CompanyDataBlock
            companyData={selectedCompanyData}
            hasBackBtn={!companyId}
            onBackBtnClick={() => dispatch(setSelectedCompany(null))}
          />
        )}
        <Row>
          <AddToMonitoring
            modalOpen={monitoringModalOpen}
            setModalOpen={setMonitoringModalOpen}
            setAddedToMonitoring={() => setCompanyMonitoring()}
          />
          <Card
            className="card-simple card-simple-padding-none space-vertical-sm has-shadow"
            title={t('companySearch.results.basic.companyReport.title')}
            extra={
              <AddToMonitoringSwitch
                addedToMonitoring={addedToMonitoring || selectedCompanyData?.isMonitored}
                setMonitoringModalOpen={setMonitoringModalOpen}
              />
            }
            style={{ width: '100%' }}
          >
            <Row gutter={[32, 16]}>
              <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
                <Row gutter={[3]} className="ant-row-pdf-gap small-blocks">
                  <Col xs={12} md={8} lg={12} xl={8}>
                    <CompanySmallBlock
                      designIcon={
                        <div
                          style={{
                            position: 'absolute',
                            bottom: -6,
                            right: 0,
                          }}
                        >
                          <IncomesIcon />
                        </div>
                      }
                      explanation={t('companySearch.results.basic.companyReport.income.disclaimer')}
                      value={checkIfPositiveValue(selectedCompanyOtherData?.incomes, 'amount', 'euro')}
                      title={t('companySearch.results.basic.companyReport.income')}
                    />
                  </Col>

                  <Col xs={12} md={8} lg={12} xl={8}>
                    <CompanySmallBlock
                      designIcon={
                        <div
                          style={{
                            position: 'absolute',
                            bottom: -20,
                            right: -30,
                          }}
                        >
                          <MarginsIcon />
                        </div>
                      }
                      explanation={t('companySearch.results.basic.companyReport.profitMargin.disclaimer')}
                      value={
                        <>
                          {checkIfPositiveValue(
                            selectedCompanyOtherData?.profitMargins,
                            'profitMargin.companies',
                            'percent'
                          )}
                        </>
                      }
                      title={t('companySearch.results.basic.companyReport.profitMargin')}
                    />
                  </Col>

                  <Col xs={12} md={8} lg={12} xl={8}>
                    <CompanySmallBlock
                      designIcon={
                        <div
                          style={{
                            position: 'absolute',
                            bottom: -4,
                            right: -15,
                          }}
                        >
                          <EmployeesIcon />
                        </div>
                      }
                      explanation={t('companySearch.results.basic.companyReport.numberOfEmployees.disclaimer')}
                      value={<>{checkIfPositiveValue(selectedCompanyOtherData?.employeesNumbers, 'total')}</>}
                      title={t('companySearch.results.basic.companyReport.numberOfEmployees')}
                    />
                  </Col>

                  <Col xs={12} md={8} lg={12} xl={8}>
                    <CompanySmallBlock
                      designIcon={
                        <div
                          style={{
                            position: 'absolute',
                            bottom: -30,
                            right: 0,
                          }}
                        >
                          <SodraIcon />
                        </div>
                      }
                      explanation={t('companySearch.results.basic.companyReport.sodra.disclaimer')}
                      value={t(
                        `companySearch.results.basic.companyReport.sodra.${selectedCompanyOtherData?.hasSodraOrVmiDebt}`
                      )}
                      title={t('companySearch.results.basic.companyReport.sodra')}
                    />
                  </Col>

                  <Col xs={12} md={8} lg={12} xl={8}>
                    <CompanySmallBlock
                      designIcon={
                        <div
                          style={{
                            position: 'absolute',
                            bottom: -6,
                            right: 0,
                          }}
                        >
                          <CreditIcon />
                        </div>
                      }
                      explanation={t('companySearch.results.basic.companyReport.creditHistory.disclaimer')}
                      value={t(
                        `companySearch.results.basic.companyReport.sodra.${selectedCompanyOtherData?.hasActiveOrLastYearPaidDebt}`
                      )}
                      title={t('companySearch.results.basic.companyReport.creditHistory')}
                    />
                  </Col>

                  <Col xs={12} md={8} lg={12} xl={8}>
                    <CompanySmallBlock
                      designIcon={
                        <div
                          style={{
                            position: 'absolute',
                            bottom: -6,
                            right: 0,
                          }}
                        >
                          <SearchesIcon />
                        </div>
                      }
                      explanation={t('companySearch.results.basic.companyReport.searches.disclaimer')}
                      value={selectedCompanyOtherData?.numberOfRequestsOverLastHalfYear || '-'}
                      title={t('companySearch.results.basic.companyReport.searches')}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12}>
                {/*TODO: These products needs to be mapped with permissions together and iterated through it*/}
                <RestrictedAccess permission="/product/companyprofessionalreport/">
                  <ReportRow
                    popoverText={
                      <Trans
                        i18nKey={'companySearch.results.basic.companyReport.professional.para'}
                        components={{ under: <u /> }}
                      />
                    }
                    btnText="global.selectReport"
                    key="professionalReport"
                    reportName={t('header.breadcrumb.professional-report')}
                    onBtnClick={() => redirect(`/search/company/${currentSelectedCompany}/professional-report`)}
                  />
                </RestrictedAccess>
                {isStartReportAllowed ? (
                  <RestrictedAccess permission="/product/companystartreport/">
                    <ReportRow
                      popoverText={
                        <Trans
                          i18nKey={'companySearch.results.basic.companyReport.start.para'}
                          components={{ under: <u /> }}
                        />
                      }
                      btnText="global.selectReport"
                      key="startReport"
                      reportName={t('header.breadcrumb.start-report')}
                      onBtnClick={() => redirect(`/search/company/${currentSelectedCompany}/start-report`)}
                    />
                  </RestrictedAccess>
                ) : null}
                {isFinancialStatementsAllowed ? (
                  <RestrictedAccess permission="/product/companyfinancialreport/">
                    <ReportRow
                      popoverText={
                        <Trans
                          i18nKey={'companySearch.results.basic.companyReport.financial.para'}
                          components={{ under: <u /> }}
                        />
                      }
                      btnText="global.selectReport"
                      key="financialStatement"
                      reportName={t('header.breadcrumb.financial-statements')}
                      onBtnClick={() => redirect(`/search/company/${currentSelectedCompany}/financial-statements`)}
                    />
                  </RestrictedAccess>
                ) : null}
                {isCompanyCreditLimitAllowed ? (
                  <RestrictedAccess permission="/product/companycreditlimitreport/">
                    <ReportRow
                      popoverText={
                        <Trans
                          i18nKey={'companySearch.results.basic.companyReport.credit.limit.para'}
                          components={{ under: <u /> }}
                        />
                      }
                      btnText="global.selectReport"
                      key="companyCreditLimit"
                      reportName={t('header.breadcrumb.company-credit-limit')}
                      onBtnClick={() => redirect(`/search/company/${currentSelectedCompany}/company-credit-limit`)}
                    />
                  </RestrictedAccess>
                ) : null}
                {isPrescoreReportAllowed ? (
                  <RestrictedAccess permission="/product/companyprescorereport/">
                    <ReportRow
                      popoverText={
                        <Trans
                          i18nKey={'companySearch.results.basic.companyReport.prescore.para'}
                          components={{ under: <u /> }}
                        />
                      }
                      btnText="global.selectReport"
                      key="prescoreReport"
                      reportName={t('header.breadcrumb.prescore-report')}
                      onBtnClick={() => redirect(`/search/company/${currentSelectedCompany}/prescore-report`)}
                    />
                  </RestrictedAccess>
                ) : null}
                {isMainInformationAllowed ? (
                  <RestrictedAccess permission="/product/companymaininformationreport/">
                    <ReportRow
                      btnText="global.selectReport"
                      key="mainInformation"
                      reportName={t('header.breadcrumb.main-information')}
                      onBtnClick={() => redirect(`/search/company/${currentSelectedCompany}/main-information`)}
                    />
                  </RestrictedAccess>
                ) : null}
                <BasicDataIcon className="card-icon" />
              </Col>
            </Row>
          </Card>
        </Row>
        {/*<Space direction="vertical" size="large">
          <Basic setVisible={setVisibleBlock} />
          <Registers setVisible={setVisibleBlock} />
          <Relations setVisible={setVisibleBlock} />
        </Space>*/}
      </div>
    </LoadingSpinner>
  );
};

export default Basic;
