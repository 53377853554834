import { default as WrappedCookieBot } from 'react-cookiebot';
import i18n from 'i18n';
import { languageCodes } from 'constants/general';

const CookieBot = () => {
  const isPdfReportPage = !!window.location.pathname.includes('pdf-print');

  if (isPdfReportPage) return null;

  return (
    <WrappedCookieBot
      domainGroupId={process.env.REACT_APP_COOKIE_BOT_KEY ?? 'e77e36c-3097-41ce-b95d-3f1008b50549'}
      language={languageCodes[i18n.language]}
    />
  );
};

export default CookieBot;
