import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';

import { ReactComponent as MailOpen } from 'assets/img/icons/MailOpen.svg';
import { contactsData } from 'store/entities/companySearchResult/selectors/unregisteredReportSelector';

const Contact = () => {
  const { t } = useTranslation();
  const { contacts } = useSelector(contactsData);
  return (
    <Col
      xs={{ span: 24, order: 2 }}
      sm={{ span: 24, order: 2 }}
      md={{ span: 24, order: 2 }}
      lg={{ span: 12, order: 1 }}
      xl={{ span: 12, order: 1 }}
      xxl={{ span: 12, order: 1 }}
    >
      <CompanyDataCard title={t('companyResult.contact.title')} icon={<MailOpen />}>
        {/* <BlockHeader icon={<MailOpen className="icon--gray" />} title={t('companyResult.contact.title')} /> */}
        <div className="content-blur">
          <CompanyDataRow type={t('companyResult.contact.legalAddress')} value={contacts?.address ?? '-'} />
          <CompanyDataRow
            type={t('companyResult.contact.email')}
            value={contacts?.email ? <a href={`mailto:${contacts?.email}`}>{contacts?.email}</a> : '-'}
          />
          <CompanyDataRow type={t('companyResult.contact.officeAddress')} value={contacts?.visitAddress ?? '-'} />
          <CompanyDataRow
            type={t('companyResult.contact.webPage')}
            value={contacts?.uri ? <a href={contacts?.uri}>{contacts?.uri}</a> : '-'}
          />
          <CompanyDataRow type={t('companyResult.contact.phone')} value={contacts?.phone ?? '-'} />
          <CompanyDataRow type={t('companyResult.contact.mobile')} value={contacts?.mobile ?? '-'} />
        </div>
      </CompanyDataCard>
    </Col>
  );
};

export default Contact;
