import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { changeLanguage, getSession, signIn, signOut, changePhoneNumber, changePassword, getFeatures } from './thunks';
import { track } from '@amplitude/analytics-browser';

const initialState = {
  features: null,
  session: null,
  hasSession: false,
  isAuthenticated: false,
  sessionKick: false,
  sessionLoading: false,
  signInPending: false,
  signOutPending: false,
  languageChangePending: false,
  phoneNumberChangePending: false,
  passwordChangePending: false,
  loginError: false,
};

const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setIsAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    setSessionKick: (state, { payload }) => {
      state.sessionKick = payload;
    },
  },
  extraReducers: {
    [getSession.fulfilled]: (state, { payload }) => {
      state.session = payload;
      state.features = payload.features;
      state.claims = payload.claims;
      state.hasSession = true;
      state.sessionLoading = false;
    },
    [getSession.pending]: state => {
      state.sessionLoading = true;
    },
    [getSession.rejected]: state => {
      state.session = null;
      state.hasSession = false;
      state.sessionLoading = false;
    },
    [getFeatures.fulfilled]: (state, { payload }) => {
      state.features = payload.features;
      state.featuresLoading = false;
    },
    [getFeatures.pending]: state => {
      state.featuresLoading = true;
      state.features = null;
    },
    [getFeatures.rejected]: state => {
      state.featuresLoading = false;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.sessionKick = false;
      state.signInPending = false;
      track('Signing Up');
      localStorage.setItem('token', payload.accessToken);
      localStorage.setItem('tokenExp', jwt_decode(payload.accessToken)?.exp);
      state.loginError = false;
    },
    [signIn.pending]: state => {
      state.signInPending = true;
      state.loginError = false;
    },
    [signIn.rejected]: state => {
      state.signInPending = false;
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExp');
      state.loginError = true;
    },
    [signOut.fulfilled]: state => {
      state.signOutPending = false;
      state.session = null;
      state.hasSession = false;
      track('Signing Out');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExp');
    },
    [signOut.pending]: state => {
      state.signOutPending = true;
    },
    [signOut.rejected]: state => {
      state.signOutPending = false;
      state.session = null;
      state.hasSession = false;
    },
    [changeLanguage.fulfilled]: state => {
      track('Language changed');
      state.languageChangePending = false;
    },
    [changeLanguage.pending]: state => {
      state.languageChangePending = true;
    },
    [changeLanguage.rejected]: state => {
      state.languageChangePending = false;
    },
    [changePhoneNumber.fulfilled]: state => {
      state.phoneNumberChangePending = false;
    },
    [changePhoneNumber.pending]: state => {
      state.phoneNumberChangePending = true;
    },
    [changePhoneNumber.rejected]: state => {
      state.phoneNumberChangePending = false;
    },
    [changePassword.fulfilled]: state => {
      state.passwordChangePending = false;
    },
    [changePassword.pending]: state => {
      state.passwordChangePending = true;
    },
    [changePassword.rejected]: state => {
      state.passwordChangePending = false;
    },
  },
});

export const { setSessionKick, setIsAuthenticated } = slice.actions;

export default slice.reducer;
